import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import 'iframe-resizer'
import SEO from '../components/seo'
import { Layout } from '../components/Layout'
import { theme } from '../components/Layout/theme'
import { PageHeader } from '../components/Header/PageHeader'
import { H1, SubTitle } from '../components/Title'
import { Container, Column, Row } from '../components/Grid'
import { ContactForm } from '../components/ContactForm'

const MaakEenAfspraakPage = ({ location }) => {
  const q = new URLSearchParams(location.search)

  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "header-massages.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Maak een afspraak" />
      <PageHeader background={data.background.childImageSharp.fluid}>
        <H1
          textAlign="center"
          color={theme.darkPink}
          style={{ marginBottom: '11px' }}>
          Maak een afspraak
        </H1>
        <SubTitle textAlign="center" color={theme.brown}>
          Zorg voor jezelf...
        </SubTitle>
      </PageHeader>
      <Container>
        <Row>
          <Column display={{ md: 12 }}>
            <ContactForm q={q} />
          </Column>
        </Row>
      </Container>
    </Layout>
  )
}
export default MaakEenAfspraakPage
