import React, { useState } from 'react'
import { Container, Row, Column } from '../Grid'
import { Paragraph } from '../Text'
import styled from '@emotion/styled'
import { Button } from '../Button'
import { Link } from 'gatsby'
import useYup from '@usereact/use-yup'
import * as yup from 'yup'

const Form = styled.form``
const Label = styled.label`
  display: block;
  font-family: BloggerSans-Light;
  font-size: 16px;
  padding-bottom: 8px;
  color: ${({ theme }) => theme.black};
`
const Input = styled.input`
  display: block;
  width: 100%;
  padding: 8px 12px;
  color: ${({ theme }) => theme.black};
  font-family: BloggerSans-Light;
  font-size: 15px;
  outline: none;
  border: 1px solid ${({ theme, error }) => (error ? 'red' : theme.black)};
  box-shadow: none;
  -webkit-appearance: none;
`
const TextArea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.black};
  width: 100%;
  resize: none;
  outline: none;
  appearance: none;
  box-shadow: none;
  padding: 12px;
  font-family: BloggerSans-Light;
  font-size: 15px;
  color: ${({ theme }) => theme.black};
`
const FormControl = styled.div`
  margin-bottom: 1em;
`

const Disclaimer = styled.div`
  border-radius: 17.5px;
  font-family: BloggerSans-Light;
  padding: 1rem 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.lightPink};
  color: ${({ theme }) => theme.black};
`

const Submit = Button.withComponent('input')

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  phone: yup.string().required(),
  message: yup.string().required(),
  privacy: yup
    .boolean()
    .required()
    .oneOf([true]),
})
const getMessage = q => {
  if (q.has('engelen-cirkel')) {
    return 'Dag Tinne, ik wil me inschrijven voor de Engelen Cirkel.'
  }
  if (q.has('jaar-voor-jezelf')) {
    return 'Dag Tinne, ik wil me inschrijven voor "Een jaar voor jezelf".'
  }
  if (q.has('angel-experience')) {
    return 'Dag Tinne, ik heb interesse in The Angel Experience en ontvang graag een link naar de workshop.'
  }
  return ''
}
const ContactForm = ({ q }) => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: getMessage(q),
    bot: '',
    privacy: false,
  })

  const [touched, setTouched] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
    bot: false,
    privacy: false,
  })

  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: true,
  })

  const handleChange = e => {
    const { name, value } = e.currentTarget
    setValues(prevState => ({
      ...prevState,
      [name]: name === 'privacy' ? !values.privacy : value,
    }))
    setTouched(prevState => ({
      ...prevState,
      [name]: true,
    }))
  }

  return (
    <Container>
      <Row style={{ paddingTop: '60px', paddingBottom: '40px' }}>
        <Column offset={{ md: 1, xs: 1 }} display={{ md: 10, xs: 10 }}>
          <Paragraph>
            Wens je een afspraak te maken, vul onderstaand formulier in. Ik neem
            zo snel mogelijk contact met je op en we plannen een afspraak in!
          </Paragraph>
        </Column>
      </Row>
      <Row style={{ paddingBottom: '40px' }}>
        <Column offset={{ md: 3 }} display={{ md: 6 }}>
          <Form
            data-netlify="true"
            netlify-honeypot="bot"
            method="POST"
            name="contact">
            <input
              type="hidden"
              name="form-name"
              value="contact"
              error={touched.name ? errors.name : undefined}
            />
            <FormControl>
              <Label htmlFor="name">Voornaam en naam*:</Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={touched.name && errors.name}
              />
            </FormControl>
            <FormControl>
              <Label
                htmlFor="email"
                error={touched.email ? errors.email : undefined}>
                E-mailadres*
              </Label>
              <Input
                type="text"
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={touched.email ? errors.email : undefined}
              />
            </FormControl>
            <FormControl>
              <Label htmlFor="phone">Tel. nr*</Label>
              <Input
                type="text"
                id="phone"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                error={touched.phone ? errors.phone : undefined}
              />
            </FormControl>
            <FormControl>
              <Label htmlFor="address">Straat en huisnummer</Label>
              <Input
                type="text"
                id="address"
                name="address"
                value={values.address}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <Label htmlFor="postal-code">Postcode</Label>
              <Input
                type="text"
                id="postal-code"
                name="postal-code"
                value={values.postalCode}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <Label htmlFor="city">Woonplaats</Label>
              <Input
                type="text"
                id="city"
                name="city"
                value={values.city}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <Label htmlFor="message">Bericht*</Label>
              <TextArea
                name="message"
                id="message"
                cols="30"
                rows="10"
                value={values.message}
                onChange={handleChange}
                error={touched.message ? errors.message : undefined}
              />
            </FormControl>
            <p style={{ display: 'none' }}>
              <label>
                Don’t fill this out if you're human:{' '}
                <input name="bot" value={values.bot} onChange={handleChange} />
              </label>
            </p>
            <br />
            <Label htmlFor="privacy">
              <input
                type="checkbox"
                name="privacy"
                id="privacy"
                checked={values.privacy}
                onChange={handleChange}
              />{' '}
              Ik heb de{' '}
              <Link to="/privacy" style={{ color: '#F78C95' }}>
                privacy verklaring
              </Link>{' '}
              gelezen en ga ermee akkoord.
            </Label>
            <br />

            <Disclaimer>
              Een afspraak kan kostenloos verplaatst of geannuleerd worden
              indien dit ten laatste 48 uur op voorhand gebeurt. Zo niet wordt
              deze aangerekend.
              <br />
              <br />
              Energetische behandelingen zijn geen vervanging voor reguliere
              medische zorg. Raadpleeg bij fysieke of psychische klachten eerst
              een huisarts of medisch deskundige. Indien het naar mijn mening
              nodig is, zal ik u doorverwijzen naar de reguliere hulpverlening.
            </Disclaimer>

            <Submit
              type="submit"
              value="Verzenden"
              disabled={!!Object.keys(errors).length}
            />
          </Form>
        </Column>
      </Row>
    </Container>
  )
}

export { ContactForm }
